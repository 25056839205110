import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Settings from "./pages";
import MailIndex from "./pages/mail";
import PermissionsCreate from "./pages/permissions/PermissionsCreate";
import PermissionsGroups from "./pages/permissions/PermissionsGroups";
import PermissionsPage from "./pages/permissions/PermissionsPage";
import CallResultTypes from "./pages/actions/index";
import Theme from "./pages/theme";
import AuthContext from "./services/AuthContext";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/index"
                          exact
                          component={(route) => (
                            <Settings
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/appearance"
                          exact
                          component={(route) => (
                            <Theme
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/mail"
                          exact
                          component={(route) => (
                            <MailIndex
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/groups/permissions/:id"
                          exact
                          component={(route) => (
                            <PermissionsPage
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/groups/create"
                          exact
                          component={(route) => (
                            <PermissionsCreate
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/groups"
                          exact
                          component={(route) => (
                            <PermissionsGroups
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.settings["A"] &&
                      props.auth.access.settings["R"] && (
                        <Route
                          path="/settings/action-types"
                          exact
                          component={(route) => (
                            <CallResultTypes
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
