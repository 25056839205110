import React from "react";
import {
  IoIosColorPalette,
  IoIosPeople,
  IoIosArrowRoundForward,
} from "react-icons/io";
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import { AppHeaderComponent } from "@unity/components";
import { Paper, Container } from '@material-ui/core';


export default function Settings({ context, history, route }) {
  console.log(context);
  const marketingAppUuid = "76635ee3-bb89-4ff4-86aa-b4dfae0887b4";
  const sandboxAppUuid = "ff0eaf73-93b8-44f0-8219-22cacda89850";
  const app_uuid = context.auth.app_uuid;
  return (
    <>
      <AppHeaderComponent
        context={context}
        name="settings"
        theme={context.theme}
      />
      {
        context.auth.access.settings !== undefined && context.auth.access.settings.MA ? (
          <div className="page-section" style={{ marginTop: 130 }}>
          <ul className="settings-menu">
            <li>
              <Link to="/settings/groups">
                <IoIosPeople size={30} />
                User Permissions
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
            <li>
              <Link to="/settings/appearance">
                <IoIosColorPalette size={30} />
                Appearance
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
            <li>
              <Link to="/settings/mail">
                <MailIcon size={30} />
                Mail
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
            {app_uuid === sandboxAppUuid && (
              <li>
                <Link to="/settings/action-types">
                  <MailIcon size={30} />
                  Result Types
                  <span className="next-arrow">
                    <IoIosArrowRoundForward size={30} />
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
        ) : (
          <Container>
            <Paper elevation={3} style={{ marginTop: "120px", textAlign: "center", padding: "20px" }}>
              <p><LockIcon style={{ color: "red", fontSize: "64pt" }}/></p>
              <p>You do not have permission to edit this application's settings.</p>
              <p>Please contact your Line Manager / Administrator for more information</p>
            </Paper>
          </Container>
        )
      }

    </>
  );
}
